<template>
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 17.3C11.1 17.4 11.2083 17.4707 11.325 17.512C11.4417 17.554 11.5667 17.575 11.7 17.575C11.8333 17.575 11.9583 17.554 12.075 17.512C12.1917 17.4707 12.3 17.4 12.4 17.3L16.65 13.05C16.8333 12.8667 16.925 12.6333 16.925 12.35C16.925 12.0667 16.825 11.825 16.625 11.625C16.425 11.425 16.1833 11.325 15.9 11.325C15.6167 11.325 15.375 11.425 15.175 11.625L11.675 15.125L10.275 13.725C10.075 13.525 9.84167 13.425 9.575 13.425C9.30833 13.425 9.075 13.525 8.875 13.725C8.675 13.925 8.575 14.1667 8.575 14.45C8.575 14.7333 8.675 14.9667 8.875 15.15L11 17.3ZM6.75 22C6.2 22 5.72933 21.8043 5.338 21.413C4.946 21.021 4.75 20.55 4.75 20V4C4.75 3.45 4.946 2.979 5.338 2.587C5.72933 2.19567 6.2 2 6.75 2H13.925C14.1917 2 14.446 2.05 14.688 2.15C14.9293 2.25 15.1417 2.39167 15.325 2.575L20.175 7.425C20.3583 7.60833 20.5 7.82067 20.6 8.062C20.7 8.304 20.75 8.55833 20.75 8.825V20C20.75 20.55 20.5543 21.021 20.163 21.413C19.771 21.8043 19.3 22 18.75 22H6.75ZM14.75 9H18.75L13.75 4V8C13.75 8.28333 13.846 8.52067 14.038 8.712C14.2293 8.904 14.4667 9 14.75 9Z"
      fill="#365FA3"
    />
  </svg>
</template>
